import React, { useState, JSX } from 'react';

import { Select, Spinner, Form, SpaceBetween, Button, FormField, Input, FormFieldProps, Checkbox } from '@cloudscape-design/components';

export interface ValidatedFormInputConfig<T> extends FormFieldProps {
    key: keyof T
    isValid?: (value: string) => boolean
    constraintDescription?: string
    allowedValues?: ReadonlyArray<string>
}

export interface ValidatedFormProps<T> {
    value: T
    inputs?: ReadonlyArray<ValidatedFormInputConfig<T>>
    onSubmit: (value: T) => Promise<void>
    termsConditions?: React.JSXElementConstructor<Record<string, never>>;
}

export const ValidatedForm = <T extends {}>(props: ValidatedFormProps<T>): JSX.Element => {
    /**
     * State tracking the current form value.
     */
    const [formValue, setFormValue] = useState<T>(props.value);
    const [hasTermsConditions, setTermsConditions] = useState(false);

    /**
     * State tracking whether all form fields are valid. 
     */
    const _isFieldValid: {[P in keyof T]?: boolean} = props.inputs.reduce<{[P in keyof T]?: boolean}>(
        (prev: {[P in keyof T]?: boolean}, config: ValidatedFormInputConfig<T>) => {
            return {
                ...prev,
                [config.key]: true
            };
        },
    {});

    const [isFieldValid, setFieldValid] = useState<{[P in keyof T]?: boolean}>(_isFieldValid);

    /**
     * State tracking whether the form has been submitted. 
     */
    const [isFormSubmitted, setFormSubmitted] = useState<boolean>(false);

    /**
     * State tracking the current form error.
     */
    const [formErrorMessage, setFormErrorMessage] = useState<string>('');

    const submitForm: (e: React.FormEvent<HTMLFormElement>) => void = (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        
        let isFormValid: boolean = true
        setFieldValid(
            props.inputs.reduce<{[P in keyof T]?: boolean}>(
                (prev: {[P in keyof T]?: boolean}, config: ValidatedFormInputConfig<T>) => {
                    const isFieldValid: boolean = config.isValid(formValue[config.key] as string)
                    isFormValid = isFormValid && isFieldValid;
                    return {
                        ...prev,
                        [config.key]: isFieldValid
                    };
                },
            {})
        );
                
        if(isFormValid) {
            setFormSubmitted(true);

            props.onSubmit(formValue).then(() =>{
                setFormSubmitted(false);
            }).catch((e: any) => {
                setFormErrorMessage(e || 'An error occurred');
                setTimeout(() => setFormErrorMessage(''), 5000);
            }).finally(() => {
                setFormSubmitted(false);
            });
        }
    };

    const isFormDisabled: () => boolean = () => {
        return !hasTermsConditions || !props.onSubmit || isFormSubmitted || !Object.values<boolean>(isFieldValid).every((value: boolean) => value);
    };

    const handleInputChange = (value: string, config: ValidatedFormInputConfig<T>) => {
        setFieldValid({...isFieldValid, [config.key]: config.isValid ? config.isValid(value) : true})
        setFormValue({...(formValue), [config.key]: value});
    };

    return (
        <form onSubmit={submitForm}>
            <Form 
                errorText={formErrorMessage}
                actions={
                    <SpaceBetween direction="horizontal" size="xs">
                        <Button variant="primary" disabled={isFormDisabled()}>
                            {isFormSubmitted ? <Spinner /> : 'Ready, Set, Climb!'}
                        </Button>
                    </SpaceBetween>
                }>
                <SpaceBetween direction="vertical" size="l">
                    {(props.inputs || []).map((config: ValidatedFormInputConfig<T>) => (
                         <FormField key={config.key as string} errorText={isFieldValid[config.key] ? null : config.constraintDescription} label={config.label} description={config.description}>
                            {config.allowedValues ? (
                                <Select
                                    expandToViewport
                                    virtualScroll
                                    selectedOption={formValue ? formValue[config.key] ? {value: formValue[config.key] as string} : null : null}
                                    placeholder="Choose an option"
                                    onChange={({ detail }) =>
                                        handleInputChange(detail.selectedOption.value, config)
                                    }
                                    options={config.allowedValues.map((value) => ({
                                        value: value
                                    }))} />
                            ) : (
                                <Input 
                                    value={formValue ? formValue[config.key] as string : null} 
                                    onChange={({ detail }) => handleInputChange(detail.value, config)} />
                            )}
                        </FormField>
                    ))}
                    {
                        props.termsConditions
                            ? <FormField description={<props.termsConditions />}
                            label="Terms and conditions"
                          >
                            <Checkbox checked={hasTermsConditions} onChange={({ detail }) => setTermsConditions(detail.checked)}>
                              I agree to the terms and conditions
                            </Checkbox>
                          </FormField>
                            : ''
                    }
                </SpaceBetween>
            </Form>
        </form>
    );
}

export default ValidatedForm;
