import React from 'react';
import { Grid, Box, Button, TextContent, SpaceBetween } from '@cloudscape-design/components';
import { I18nProvider } from '@cloudscape-design/components/i18n';
import messages from '@cloudscape-design/components/i18n/messages/all.en';

import './index.scss';


interface HeaderProps {
  onButtonClick: () => void;
}

const LOCALE = 'en';

const EventHeader: React.FC<HeaderProps> = ({ onButtonClick }) => {
  return (
    <section className="header-holder">
      <I18nProvider locale={LOCALE} messages={[messages]}>
          <SpaceBetween direction="vertical" size="xl">
            <Box variant='h3' fontSize='heading-s'>April 3, 2024 | Innovation Corner, Palais des Congrès de Paris</Box>
            <Grid
              className="grid-align-items"
              gridDefinition={[
                { colspan: { default: 2 } },
                { colspan: { default: 10 } }
              ]}
            >
              <TextContent>
                <img className="event-logo" src="/static/aws.svg" />
              </TextContent>
              <SpaceBetween size="m" direction='vertical'>
                <h1 className="event-title">Summit Ascent</h1>
                <h2 className="event-subtitle">Paris 2024</h2>
              </SpaceBetween>
            </Grid>
            <section className="button-holder">
              <Button fullWidth variant="primary" onClick={onButtonClick}>
              Register now & Climb the Leaderboard &#62;&#62;
              </Button>
            </section>
          </SpaceBetween>
      </I18nProvider>
    </section>
  );
};

export default EventHeader;
