import { FC, useEffect, useState } from 'react';
import { Header, ColumnLayout, Box, StatusIndicator, SpaceBetween } from '@cloudscape-design/components';
import { WidgetConfig } from 'components/ConfigurableWidget';
import { generateClient } from 'aws-amplify/api';
import { Run, RunCurrentStatus } from 'API';

import { getEventCurrentRun, getEventLatestRun } from 'graphql/queries';
import { onRunUpdates } from 'graphql/subscriptions';

import EmptyState from 'components/EmptyState';

import { StopWatch } from 'components/StopWatch';
import { RunStatusIndicator } from 'components/RunStatusIndicator'

const CurrentRunStatsHeader: FC = () => <Header>🧗‍♀️ Run Statistics</Header>;

const CurrentRunStatsContent: FC = () => {
  const [isLoading, setLoadingState] = useState<boolean>(true);
  const [currentRun, setCurrentRun] = useState<Run>(null);

  const apiClient = generateClient();

  useEffect(() => {
    let subscription: any;
  
    const fetchAndSubscribe = async () => {
      let currentRun;
  
      try {
        // Attempt to fetch the current run
        const currentRunResponse = await apiClient.graphql({
          query: getEventCurrentRun,
          variables: { event: { id: 'ParisSummit2024' } },
        });
        currentRun = currentRunResponse.data.getEventCurrentRun;
      } catch (error) {
        console.error('Fetch current run error:', error);
        // If fetching the current run fails, attempt to fetch the latest run
        try {
          const latestRunResponse = await apiClient.graphql({
            query: getEventLatestRun,
            variables: { event: { id: 'ParisSummit2024' } },
          });
          currentRun = latestRunResponse.data.getEventLatestRun;
        } catch (latestRunError) {
          console.error('Fetch latest run error:', latestRunError);
          currentRun = null;
        }
      }
  
      setCurrentRun(currentRun);
      setLoadingState(false);

      // Setting up the subscription
      try {
        subscription = apiClient.graphql({ query: onRunUpdates }).subscribe({
          next: ({ data }) => {
            setCurrentRun(prev => {
              if (!prev || data.onRunUpdates.id === prev.id || data.onRunUpdates.currentStatus === RunCurrentStatus.RUNNING) {
                return data.onRunUpdates;
              }
              return prev;
            });
          },
          error: subscriptionError => console.error('Subscription error:', subscriptionError),
        });
      } catch (subscriptionSetupError) {
        console.error('Subscription setup error:', subscriptionSetupError);
      }
    };
  
    fetchAndSubscribe();
  
    return () => {
      subscription?.unsubscribe();
    };
  }, []);  

  return (
    <div>
      {currentRun ? (
        <ColumnLayout columns={4} variant="text-grid">
          <div>
            <Box variant="awsui-key-label">Climber Name</Box>
            {currentRun.player.firstName + " " + currentRun.player.lastName}
          </div>
          <div>
            <Box variant="awsui-key-label">Team</Box>
            {currentRun.player.companyName}
          </div>
          <div>
            <Box variant="awsui-key-label">Status</Box>
            <RunStatusIndicator currentStatus={currentRun.currentStatus} finishType={currentRun.finishType} />
          </div>
          <div>
            <Box variant="awsui-key-label">Run Time</Box>
            <StopWatch startTime={currentRun.currentStatus == RunCurrentStatus.FINISHED ? null : currentRun.startedAt} totalTime={currentRun.currentStatus == RunCurrentStatus.FINISHED ? (currentRun.stats || {}).time : null}/>
          </div>
          <div>
            <Box variant="awsui-key-label">Holds Reached</Box>
            {(currentRun.stats || {}).reachedHolds || 0}
          </div>
          <div>
            <Box variant="awsui-key-label">Mistakes Made</Box>
            {((currentRun.stats || {}).wrongHolds || []).length || 0}
          </div>
          <div>
            <Box variant="awsui-key-label">Run Difficulty</Box>
            {currentRun.difficulty}
          </div>
        </ColumnLayout>
      ) : (
        <EmptyState
          title="You'll be the first to climb the wall..."
          description="Register now !"
          verticalCenter={true}
          isLoading={isLoading}
        />
      )}
    </div>
  );  
};

export const CurrentRunStatsWidgetConfig: WidgetConfig = {
  definition: { defaultRowSpan: 2, defaultColumnSpan: 2 },
  data: {
    icon: 'list',
    title: 'Current Run Stats',
    description: 'Overview of all your resources',
    header: CurrentRunStatsHeader,
    content: CurrentRunStatsContent,
  },
};

export default CurrentRunStatsWidgetConfig;



