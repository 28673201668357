// Copyright Amazon.com, Inc. or its affiliates. All Rights Reserved.
// SPDX-License-Identifier: MIT-0
import React from 'react';
import { Header, PieChart } from '@cloudscape-design/components';
import { BoardProps } from '@cloudscape-design/board-components/board';
import { colorChartsStatusPositive, colorChartsStatusHigh } from '@cloudscape-design/design-tokens';
import { percentageFormatter } from './chart-commons';
import * as icons from './icons';

export interface WidgetDataType {
    icon: keyof typeof icons;
    title: string;
    description: string;
    disableContentPaddings?: boolean;
    provider?: React.JSXElementConstructor<{ children: React.ReactElement }>;
    header: React.JSXElementConstructor<Record<string, never>>;
    content: React.JSXElementConstructor<Record<string, never>>;
    footer?: React.JSXElementConstructor<Record<string, never>>;
    staticMinHeight?: number;
}
  
export type DashboardWidgetItem = BoardProps.Item<WidgetDataType>;
  
export type WidgetConfig = Pick<DashboardWidgetItem, 'definition' | 'data'>;

export const zoneStatus: WidgetConfig = {
  definition: { defaultRowSpan: 4, defaultColumnSpan: 2, minRowSpan: 3 },
  data: {
    icon: 'pieChart',
    title: 'Zone status',
    description: 'Zone status report',
    header: ZoneStatusHeader,
    content: ZoneStatusContent,
    staticMinHeight: 450,
  },
};

function ZoneStatusHeader() {
  return (
    <Header variant="h2">Total Attempted Runs</Header>
  );
}

function ZoneStatusContent() {
  return (
    <PieChart
      hideFilter={true}
      fitHeight={true}
      size="small"
      data={[
        { title: 'Hard Runs', value: 25, color: colorChartsStatusPositive },
        { title: 'Medium Runs', value: 15, color: colorChartsStatusHigh },
        { title: 'Easy Runs', value: 5, color: colorChartsStatusPositive },
      ]}
      ariaLabel="Attempted Runs"
      ariaDescription="Pie chart summarizing the status of all zones."
      segmentDescription={(datum, sum) => `${datum.value} zones, ${percentageFormatter(datum.value / sum)}`}
      detailPopoverContent={(datum, sum) => [
        {
          key: 'Zone count',
          value: datum.value,
        },
        {
          key: 'Percentage',
          value: percentageFormatter(datum.value / sum),
        },
      ]}
      i18nStrings={{
        filterLabel: 'Filter displayed data',
        filterPlaceholder: 'Filter data',
        filterSelectedAriaLabel: 'selected',
        detailPopoverDismissAriaLabel: 'Dismiss',

        legendAriaLabel: 'Legend',
        chartAriaRoleDescription: 'pie chart',
        segmentAriaRoleDescription: 'segment',
      }}
    />
  );
}