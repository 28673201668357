import { Box, BoxProps } from '@cloudscape-design/components';
import { FC } from 'react';

interface MedalRankProps extends BoxProps {
    rank: number
}
    
export const MedalRank: FC<MedalRankProps> = (props: MedalRankProps) => {
  return (
    <Box {...props}>{
        props.rank == 1
            ? '🥇'
            : props.rank == 2
                ? '🥈'
                : props.rank == 3
                    ? '🥉'
                    : props.rank
    }</Box>
  );
};

export default MedalRank;
