import { Box, BoxProps } from '@cloudscape-design/components';
import { FC, useState, useEffect } from 'react';

interface StopWatchProps extends BoxProps {
    startTime?: number,
    totalTime?: number
}
    
export const StopWatch: FC<StopWatchProps> = (props: StopWatchProps) => {
  const [elapsedTime, setElapsedTime] = useState(0);
  let interval: NodeJS.Timeout;

  useEffect(() => {
    if (props.totalTime) {
      setElapsedTime(props.totalTime * 1000);
 
    } else if (props.startTime) {
      // Update the elapsed time every millisecond
      interval = setInterval(() => {
        const currentTime = Date.now(); // Get current time in milliseconds
        const startTimeInMs = props.startTime * 1000; // Convert start time to milliseconds
        setElapsedTime(currentTime - startTimeInMs);
     
      }, 1); // Run evey millisecond
    
    }; 

    // Clear interval on component unmount
    return () => {
      if(interval) {
        clearInterval(interval);
      }
    };
  }, [props.startTime]);

  const getDisplayValue = (timeInMs?: number): string => {
    if (timeInMs === undefined || timeInMs === null || isNaN(timeInMs)) {
      return '-';
    }
  
    const minutes: number = Math.floor(timeInMs / 60000); // 60,000 milliseconds in a minute
    const seconds: number = Math.floor((timeInMs % 60000) / 1000); // Remaining milliseconds converted to seconds
    const milliseconds: number = Math.floor(timeInMs % 1000); // Remaining milliseconds
  
    const mm: string = minutes.toString().padStart(2, '0');
    const ss: string = seconds.toString().padStart(2, '0');
    const sss: string = milliseconds.toString().padStart(3, '0');
  
    return `${mm}:${ss}.${sss}`;
  };

  return (
    <Box {...props}>{getDisplayValue(elapsedTime)}</Box>
  );
};

export default StopWatch;
