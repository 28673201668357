import { FC } from 'react';
import { Tabs } from '@cloudscape-design/components';
import { I18nProvider } from '@cloudscape-design/components/i18n';
import messages from '@cloudscape-design/components/i18n/messages/all.en';
  
import LeaderboardTab from './tabs/LeaderboardTab';

import './index.scss';

const LOCALE = 'en';

const EventPage: FC = () => {
    return (
        <I18nProvider locale={LOCALE} messages={[messages]}>
            <section className='tab-holder'>
                <Tabs
                    disableContentPaddings
                    tabs={[
                        {
                        id: "_title",
                        label: "Summit Ascent Paris 2024",
                        disabled: true
                        },
                        /**{
                        label: "Aperçu",
                        id: "first",
                        content: <OverviewTab />
                        },**/
                        {
                        label: "Live Scores",
                        id: "liveScores",
                        content: <LeaderboardTab />
                        }
                    ]}
                    />
            </section>
        </I18nProvider>

    );
};

export default EventPage;
