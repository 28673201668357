import { FC } from 'react';
import {  StatusIndicator } from '@cloudscape-design/components';

import { RunCurrentStatus, RunFinishType } from 'API';

export interface RunStatusIndicatorProps {
  currentStatus?: RunCurrentStatus
  finishType?: RunFinishType
}

export const RunStatusIndicator: FC<RunStatusIndicatorProps> = (props: RunStatusIndicatorProps) => {
    if(props.currentStatus==RunCurrentStatus.FINISHED) {
        return props.finishType==RunFinishType.SUCCESS
            ? <StatusIndicator>Successful</StatusIndicator>
            : props.finishType==RunFinishType.FAIL_FALL
            ? <StatusIndicator type="error">Failed</StatusIndicator>
            : props.finishType==RunFinishType.FAIL_TIMEOUT
            ? <StatusIndicator type="error">Timeout</StatusIndicator>
            : <StatusIndicator type="stopped">Ended</StatusIndicator>
    }

    if(props.currentStatus==RunCurrentStatus.IN_QUEUE) {
        return <StatusIndicator type="pending">In queue</StatusIndicator>
    }

    if(props.currentStatus==RunCurrentStatus.RUNNING) {
        return <StatusIndicator type="loading">Running...</StatusIndicator>
    }

    if(props.currentStatus==RunCurrentStatus.CANCELLED) {
        return <StatusIndicator type="stopped">Cancelled</StatusIndicator>
    }
    
    return <StatusIndicator type="warning">Unknown</StatusIndicator>
};


export default RunStatusIndicator;
