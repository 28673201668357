import { I18nProvider } from '@cloudscape-design/components/i18n';
import messages from '@cloudscape-design/components/i18n/messages/all.en';

import ConfigurableDashboard from 'components/ConfigurableDashboard';
import EventLayout from '../components/EventLayout';

const LOCALE = 'en';

export default () => {  
  let navigationOpen: boolean = false;

  return (
    <I18nProvider locale={LOCALE} messages={[messages]}>
      <EventLayout>
        <ConfigurableDashboard/>
      </EventLayout>
    </I18nProvider>
  );
}
