import React, { useState } from 'react';

import { ButtonDropdown } from '@cloudscape-design/components';
import { BoardProps, BoardItem } from '@cloudscape-design/board-components';

import { boardItemI18nStrings } from '../ConfigurableDashboard/boardItemI18nStrings';

import RemoveDialog from './components/RemoveDialog';

export type DashboardWidgetItem = BoardProps.Item<WidgetDataType>;


export type WidgetConfig = Pick<DashboardWidgetItem, 'definition' | 'data'>;

export interface WidgetDataType {
  icon: string;
  title: string;
  description: string;
  disableContentPaddings?: boolean;
  provider?: React.JSXElementConstructor<{ children: React.ReactElement }>;
  header: React.JSXElementConstructor<any>;
  content: React.JSXElementConstructor<any>;
  footer?: React.JSXElementConstructor<any>;
  staticMinHeight?: number;
}

interface BaseWidgetProps {
  props?: {[key: string]: any}
  config: WidgetDataType;
  onRemove: () => void;
  removeConfirmationText?: string;
  actions?: ReadonlyArray<{ text: string; onClick: () => void }>;
}

export default ({ props, config, onRemove, actions = [], removeConfirmationText }: BaseWidgetProps) => {
  const [showRemoveDialog, setShowRemoveDialog] = useState(false);
  return (
    <>
      <BoardItem
        header={<config.header {...(props || {})} />}
        disableContentPaddings={config.disableContentPaddings}
        i18nStrings={boardItemI18nStrings}
        /*settings={
          <ButtonDropdown
            items={[
              ...actions.map(action => ({ id: action.text, text: action.text })),
              { id: 'remove', text: 'Remove' },
            ]}
            ariaLabel="Widget settings"
            variant="icon"
            onItemClick={event => {
              if (event.detail.id === 'remove') {
                if (removeConfirmationText) {
                  setShowRemoveDialog(true);
                } else {
                  onRemove();
                }
              } else {
                const actionMatch = actions.find(action => action.text === event.detail.id)!;
                actionMatch.onClick();
              }
            }}
          />
        }*/
        footer={config.footer && <config.footer {...(props || {})}/>}
      >
        <config.content {...(props || {})}/>
      </BoardItem>
      {showRemoveDialog && (
        <RemoveDialog
          title={removeConfirmationText!}
          onDismiss={() => setShowRemoveDialog(false)}
          onConfirm={() => {
            setShowRemoveDialog(false);
            onRemove();
          }}
        />
      )}
    </>
  );
}