import { useEffect } from 'react';
import { Amplify } from 'aws-amplify';

import { Theme, applyTheme } from '@cloudscape-design/components/theming';
import { TopNavigation } from '@cloudscape-design/components';
import { I18nProvider } from '@cloudscape-design/components/i18n';
import messages from '@cloudscape-design/components/i18n/messages/all.en';


// Use the reset method to remove the custom theme
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import AllEventsPage from './pages/AllEventsPage';
import EventPage from './pages/EventPage';

import { CONNECTION_STATE_CHANGE, ConnectionState } from 'aws-amplify/api';
import { Hub } from 'aws-amplify/utils';

const theme: Theme = {
  tokens: {
    colorBackgroundButtonPrimaryDefault: '#ec6907',
    colorBackgroundButtonPrimaryActive: '#d35e06',
    colorBackgroundButtonPrimaryDisabled: '',
    colorBackgroundButtonPrimaryHover: '#f49c59',
    fontSizeBodyM: '12px',
    fontSizeBodyS: '10px',
    fontSizeDisplayL: '42px',
    fontWeightHeadingXl: '600',
    fontWeightHeadingL: '600',
    fontWeightHeadingM: '600',
    fontWeightHeadingS: '600',
    fontWeightHeadingXs: '600',
    colorTextAccent: '#ec6907',
  },
  contexts: {
    // Values for visual contexts. Unless specified, default values will be applied
    
    header: {
      tokens: {
        colorBackgroundLayoutMain: '#133279',
        colorBackgroundButtonPrimaryDefault: '#ec6907',
        colorBackgroundButtonPrimaryActive: '#ffffff',
        colorBackgroundButtonPrimaryDisabled: '#ffffff',
        colorBackgroundButtonPrimaryHover: '#ffffff',
        colorTextButtonPrimaryDefault: 'rgba(0,0,0,.87)',
        colorTextButtonPrimaryActive: 'rgba(0,0,0,.87)',
        colorTextButtonPrimaryHover: 'rgba(0,0,0,.87)'
      }
    },
    "top-navigation": {
      tokens: {
        colorBackgroundContainerContent: '#3b3b3b'
      }
    }
  }
};

const { reset } = applyTheme({ theme });

// Import other pages as needed
const LOCALE = 'en';

Amplify.configure({
  API: {
    GraphQL: {
      endpoint: 'https://eu-west-3-api.summitascent.iqanto.cloud/graphql',
      defaultAuthMode: 'iam',
      region: 'eu-west-3'
    }
  },
  Auth: {
    Cognito: {
      identityPoolId: "us-east-1:aa947f61-8fc8-48b7-a762-84f108b8f3b9",
      allowGuestAccess: true,
    }
  }
});



const App: React.FC = () => {  
  useEffect(() => {
    Hub.listen('api', (data: any) => {
      const { payload } = data;
      if (payload.event === CONNECTION_STATE_CHANGE) {
        const connectionState = payload.data.connectionState as ConnectionState;
        console.log(data);
      }
    });
  })

  return (
    <I18nProvider locale={LOCALE} messages={[messages]}>
      <TopNavigation
        identity={{
          href: "/",
          logo: {
            src: "/static/logo.svg",
            alt: "iQanto"
          }
        }}
        utilities={[
          {
            type: "button",
            text: "About iQanto",
            href: "https://iqanto.net/",
            external: true,
            externalIconAriaLabel: " (opens in a new tab)"
          },
          {
            type: "button",
            text: "Architecture",
            href: "/static/architecture.pdf",
            external: true,
            externalIconAriaLabel: " (opens in a new tab)"
          },
          /**{
            type: "button",
            text: "Nos prochains événements",
            href: "/events",
          },

          {
            type: "button",
            iconName: "notification",
            title: "Notifications",
            ariaLabel: "Notifications (unread)",
            badge: true,
            disableUtilityCollapse: false
          }**/
        ]}
      />
      <BrowserRouter basename="/">
        <Routes>
          <Route path="/" element={<EventPage />} />
          <Route path="/events" element={<AllEventsPage />} />
        </Routes>
      </BrowserRouter>
    </I18nProvider>
  );
};

export default App;
