import React from 'react';
import { Box, SpaceBetween, StatusIndicator } from '@cloudscape-design/components';
import style from './style.module.scss';

interface EmptyStateProps {
  icon?: React.ReactNode;
  title: string;
  verticalCenter?: boolean;
  description: string;
  action?: React.ReactNode;
  isLoading?: boolean;
}

export default (props: EmptyStateProps) => (
  <div className={props.verticalCenter ? style.verticalCenter : ''}>
    <Box margin={{ vertical: 'xs' }} textAlign="center" color="text-body-secondary">
      {props.isLoading ? <StatusIndicator type="loading">Loading...</StatusIndicator>
      : <SpaceBetween size="xxs">
        <div>
          {props.icon && <div>{props.icon}</div>}
          <Box variant="strong" color="inherit">
            {props.title}
          </Box>
          <Box variant="p" color="inherit">
            {props.description}
          </Box>
        </div>
        {props.action}
      </SpaceBetween>
      }
    </Box>
  </div>
);