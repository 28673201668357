import React, { RefObject, createRef, useState, ReactNode } from 'react';
import { AppLayout, ContentLayout, SplitPanel } from '@cloudscape-design/components';
import { I18nProvider } from '@cloudscape-design/components/i18n';

import RegistrationPanel from 'components/RegistrationPanel';

import EventHeader from '../Header';

import messages from '@cloudscape-design/components/i18n/messages/all.en';

import './index.scss';

interface EventLayoutProps {
  children: ReactNode;
  disableOverlap?: boolean
}

const LOCALE = 'en';

/**
 * EventLayout component.
 * 
 * @component
 * @param {ReactNode} children The child components to render within the EventLayout.
 * @returns {JSX.Element} Rendered EventLayout component.
 */
const EventLayout: React.FC<EventLayoutProps> = ({ children, disableOverlap }) => {
  const wrapperRef: RefObject<HTMLDivElement> = createRef();
  const [registrationPanelOpened, setRegistrationPanel] = useState(false);

  /**
   * Toggles the registration panel.
   */
  const toggleRegistrationPanel = () => {
    setRegistrationPanel(prevState => !prevState);
  };

  return (
    <div ref={wrapperRef}>
      <I18nProvider locale={LOCALE} messages={[messages]}>
        <section className='app-layout-holder'>
        <AppLayout
          navigationHide={true}
          /*toolsOpen={registrationPanelOpened}
          tools={<RegistrationHelpPanel />}
          toolsWidth={400}*/
          toolsHide={true}
          maxContentWidth={1100}
          onSplitPanelToggle={toggleRegistrationPanel}
          splitPanelOpen={registrationPanelOpened}
          splitPanelPreferences={{position: "side"}}
          splitPanel={<RegistrationPanel onRegistrationSubmit={toggleRegistrationPanel} />}
          content={
            <ContentLayout disableOverlap={disableOverlap || false} header={<EventHeader onButtonClick={toggleRegistrationPanel} />}>
              {children}
            </ContentLayout>
          }
        />
        </section>
      </I18nProvider>
    </div>
  );
}

export default EventLayout;
