import React, { useState, useEffect, FC } from 'react';

import { generateClient } from 'aws-amplify/api';

import { Box, SplitPanel, SpaceBetween } from '@cloudscape-design/components';

import { ValidatedForm, ValidatedFormInputConfig } from 'components/ValidatedForm';

import { RunPlayerInfoInput, CreateRunMutation } from 'API';
import { createRun } from 'graphql/mutations';

export const FORM_INPUTS_CONFIGS: ReadonlyArray<ValidatedFormInputConfig<RunPlayerInfoInput>> = [
    {
        key: 'firstName',
        description: 'We want to know who’s conquering our wall! This will be displayed publicly.',
        isValid: (value: string) => value && Boolean(value.trim()),
        constraintDescription: 'This field is required.',
        label: 'First Name'
    },
    {
        key: 'lastName',
        isValid: (value: string) => value && Boolean(value.trim()),
        constraintDescription: 'This field is required.',
        label: 'Last Name'
    },
    {
        key: 'email',
        isValid: (value: string) => value && /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value),
        constraintDescription: 'This field must be a valid email address.',
        label: 'Business Email Address'
    },
    {
        key: 'companyName',
        description: 'Let’s give your company a shoutout! This will be displayed publicly.',
        isValid: (value: string) => value && Boolean(value.trim()),
        constraintDescription: 'This field is required.',
        label: 'Company Name'
    },
    {
        key: 'companyRole',
        isValid: (value: string) => value && Boolean(value.trim()),
        constraintDescription: 'Please select your role.',
        label: 'Job Role',
        allowedValues: [
            "Academic / Researcher",
            "Business Executive",
            "Advisor / Consultant",
            "Entrepreneur (Founder/Co-Founder)",
            "Developer / Engineer",
            "IT Executive",
            "IT Professional or Technical Manager",
            "Press / Media Analyst",
            "Solution or Systems Architect",
            "Student",
            "System Administrator",
            "Venture Capitalist",
            "Sales / Marketing"
        ]
    }
]

export interface RegistrationPanelProps {
    onRegistrationSubmit?: (data: CreateRunMutation) => void
}

export const RegistrationPanel: FC<RegistrationPanelProps> = (props: RegistrationPanelProps) => {
    const apiClient = generateClient();

    const  handleFormSubmit: (value: RunPlayerInfoInput) => Promise<void> = async (value: RunPlayerInfoInput) => {
        return await apiClient.graphql({
            query: createRun,
            variables: {
                event: {id: 'ParisSummit2024'},
                player: value
            }
        }).then((res: {data: CreateRunMutation}) => {
            if(props.onRegistrationSubmit) {
                props.onRegistrationSubmit(res.data);
            }
        }).catch( e => {
            console.warn(e);
            throw e.errors[0].message
        });
    };

    return (
        <SplitPanel hidePreferencesButton={true} header="🦾 Up to the Challenge?">
            <SpaceBetween direction='vertical' size='l'>
            <Box variant='p'>Climb to the top and leave your mark on our leaderboard! Fill out the form below to join the queue.</Box>
            <ValidatedForm
                value={null}
                inputs={FORM_INPUTS_CONFIGS}
                onSubmit={val => handleFormSubmit(val)}
                termsConditions={() => (<Box fontWeight="normal" variant="small">
                    By checking this box, I confirm that I have read, understood, and agree to the following Terms and Conditions related to the use of the connected climbing wall:
                    (1) I acknowledge that participating in climbing activities requires a reasonable level of physical fitness, and I confirm that I am physically capable of engaging in this activity without undue risk to my health or safety. I have consulted with a medical professional if I have any concerns regarding my physical condition and my ability to participate in such activities. (2) I understand that my participation may be photographed or recorded, and I hereby grant the organizers, their affiliates, and sponsors the right to use any photographs, video recordings, or any other visual or audio portrayals of me made during my use of the climbing wall in all media, including social media, for advertising, promotional, or other purposes.

                  </Box>)
                }/>
            </SpaceBetween>
        </SplitPanel>
    );
}

export default RegistrationPanel;
