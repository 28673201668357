import { FC, useEffect, useState, useCallback } from 'react';
import { Header, Table, TableProps } from '@cloudscape-design/components';
import { WidgetConfig } from 'components/ConfigurableWidget';
import { generateClient } from 'aws-amplify/api';
import { Run, RunCurrentStatus, RunDifficulty, RunFinishType } from 'API';

import { getEventLeaderboard } from 'graphql/queries';
import { onRunUpdates } from 'graphql/subscriptions';

import EmptyState from 'components/EmptyState';
import { MedalRank } from 'components/MedalRank';
import { StopWatch } from 'components/StopWatch';

export interface RankedRun extends Run {
  rank: number
};

const LeaderboardEasyWidgetHeader: FC = () => <Header>🥉 Summit Seekers (Easy)</Header>;

const LeaderboardEasyWidgetContent: FC = () => {
  const [isLoading, setLoadingState] = useState<boolean>(true);
  const [leaderboard, setLeaderboard] = useState<{ [id: string]: Run }>({});

  const getLeaderboard = useCallback(() => {
    return Object.values(leaderboard).sort( (a: Run, b: Run) => {
      return ((a.stats || {}).time + 5 * ((a.stats || {}).wrongHolds || []).length) - ((b.stats || {}).time + 5 * ((b.stats || {}).wrongHolds || []).length) 
    }).map((run: Run, rank: number) => {
      return {...run, rank: rank + 1};
    });
  }, [leaderboard]);

  const apiClient = generateClient();

  const columnDefinitions: Array<TableProps.ColumnDefinition<RankedRun>> = [
    {
      id: 'rank',
      header: '🏆',
      cell: item => <MedalRank rank={item.rank} />,
      minWidth: 50,
      maxWidth: 50,
      width: 50,
      isRowHeader: true,
    },
    {
      id: 'name',
      header: 'Climber Name',
      cell: item => item.player?.firstName + ' ' + item.player?.lastName,
      isRowHeader: true,
    },
    {
      id: 'company',
      header: 'Team',
      cell: item => ((item || {}).player || {}).companyName,
      isRowHeader: true
    },
    {
      id: 'mistakesMade',
      header: 'Mistakes Made',
      cell: item => (((item || {}).stats || {}).wrongHolds || []).length,
      isRowHeader: true
    },
    {
      id: 'time',
      header: 'Run Time',
      cell: item => <StopWatch totalTime={(item.stats || {}).time}/>,
      isRowHeader: true
    }
  ];

  useEffect(() => {
    let subscription: any;

    const fetchAndSubscribe = async () => {
      try {
        const res = await apiClient.graphql({
          query: getEventLeaderboard,
          variables: { event: { id: 'ParisSummit2024' }, difficulty: RunDifficulty.EASY}
        });

        // Set the initial queue.
        setLeaderboard((res.data.getEventLeaderboard || []).reduce<{[id: string]: Run}>( (prev: {[id: string]: Run}, run: Run) => {
          prev[run.id] = run;
          return prev;
        }, {}));
        setLoadingState(false);

        subscription = apiClient.graphql({ query: onRunUpdates }).subscribe({
          next: ({ data }) => {
            setLeaderboard(prev => {
              const updatedRuns = { ...prev };
              if (data.onRunUpdates.currentStatus === RunCurrentStatus.FINISHED && data.onRunUpdates.finishType === RunFinishType.SUCCESS && data.onRunUpdates.difficulty === RunDifficulty.EASY) {
                updatedRuns[data.onRunUpdates.id] = data.onRunUpdates;
              };
              return updatedRuns;
            });
          },
          error: error => console.error('Subscription error:', error),
        });
      } catch (error) {
        console.error('Fetching error:', error);
      }
    };

    fetchAndSubscribe();

    return () => subscription?.unsubscribe();
  }, []);

  return (
    getLeaderboard().length > 0 ? (
      <Table
        variant="borderless"
        resizableColumns={true}
        items={getLeaderboard()}
        columnDefinitions={columnDefinitions}
        enableKeyboardNavigation={true}
      />
    ) : (
      <EmptyState
        isLoading={isLoading}
        title="The peak awaits! Be the first to leave your mark and start your climb to the top!"
        description="Start your climb to the top!"
        verticalCenter={true}
      />
    )
  );
};

export const LeaderboardEasyWidgetConfig: WidgetConfig = {
  definition: { defaultRowSpan: 2, defaultColumnSpan: 2 },
  data: {
    icon: 'table',
    title: 'Up next',
    description: 'Up next',
    disableContentPaddings: false,
    header: LeaderboardEasyWidgetHeader,
    content: LeaderboardEasyWidgetContent
  }
};

export default LeaderboardEasyWidgetContent;
