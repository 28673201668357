import { FC, useEffect, useState, useCallback } from 'react';
import { Header, Table, TableProps, StatusIndicator } from '@cloudscape-design/components';
import { WidgetConfig } from 'components/ConfigurableWidget';
import { generateClient } from 'aws-amplify/api';
import { Run, RunCurrentStatus } from 'API';

import { getEventQueue } from 'graphql/queries';
import { onRunUpdates } from 'graphql/subscriptions';

import EmptyState from 'components/EmptyState';
import { RunStatusIndicator } from 'components/RunStatusIndicator';

const UpNextWidgetHeader: FC = () => <Header>⏳ Up next</Header>;

const UpNextWidgetContent: FC = () => {
  const [isLoading, setLoadingState] = useState<boolean>(true);
  const [queuedRuns, setQueuedRuns] = useState<{ [id: string]: Run }>({});

  const getQueuedRuns = useCallback(() => {
    return Object.values(queuedRuns).sort( (a: Run, b: Run) => {
      return a.createdAt - b.createdAt;
    });
  }, [queuedRuns]);

  const apiClient = generateClient();

  const columnDefinitions: Array<TableProps.ColumnDefinition<Run>> = [
    {
      id: 'name',
      header: 'Climber Name',
      cell: item => item.player?.firstName + ' ' + item.player?.lastName,
      isRowHeader: true,
    },
    {
      id: 'company',
      header: 'Team',
      cell: item => ((item || {}).player || {}).companyName,
      isRowHeader: true
    },
    {
      id: 'status',
      header: 'Status',
      cell: item => (<RunStatusIndicator currentStatus={item.currentStatus} finishType={item.finishType} />),
      isRowHeader: true
    }
  ];

  useEffect(() => {
    let subscription: any;
    const deletionTimers: { [id: string]: NodeJS.Timeout } = {};
  
    const fetchAndSubscribe = async () => {
      try {
        const res = await apiClient.graphql({
          query: getEventQueue,
          variables: { event: { id: 'ParisSummit2024' } }
        });
  
        setQueuedRuns((res.data.getEventQueue || []).reduce<{[id: string]: Run}>((prev, run) => {
          prev[run.id] = run;
          return prev;
        }, {}));
        setLoadingState(false);
  
        subscription = apiClient.graphql({ query: onRunUpdates }).subscribe({
          next: ({ data }) => {
            setQueuedRuns(prev => {
              const updatedRuns = { ...prev };
              if (data.onRunUpdates.currentStatus === RunCurrentStatus.IN_QUEUE) {
                updatedRuns[data.onRunUpdates.id] = data.onRunUpdates;
              } else if ([RunCurrentStatus.CANCELLED, RunCurrentStatus.RUNNING].includes(data.onRunUpdates.currentStatus) && updatedRuns[data.onRunUpdates.id]) {
                // Update only if the run already exists
                updatedRuns[data.onRunUpdates.id] = data.onRunUpdates;
                // Schedule for deletion once, 10 seconds after the first update to CANCELLED or RUNNING
                if (!deletionTimers[data.onRunUpdates.id]) {
                  deletionTimers[data.onRunUpdates.id] = setTimeout(() => {
                    setQueuedRuns(prevRuns => {
                      const newRuns = { ...prevRuns };
                      delete newRuns[data.onRunUpdates.id];
                      return newRuns;
                    });
                    delete deletionTimers[data.onRunUpdates.id];
                  }, 10000);
                }
              }
              return updatedRuns;
            });
          },
          error: error => console.error('Subscription error:', error),
        });
      } catch (error) {
        console.error('Fetching error:', error);
      }
    };
  
    fetchAndSubscribe();
  
    return () => {
      subscription?.unsubscribe();
      // Clear all pending deletion timers when component unmounts
      Object.values(deletionTimers).forEach(clearTimeout);
    };
  }, []);
  

  return (
    getQueuedRuns().length > 0 ? (
      <Table
        variant="borderless"
        resizableColumns={true}
        items={getQueuedRuns()}
        columnDefinitions={columnDefinitions}
        enableKeyboardNavigation={true}
      />
    ) : (
      <EmptyState
        title="Queue is currently empty"
        description="Register now !"
        verticalCenter={true}
        isLoading={isLoading}
      />
    )
  );
};

export const UpNextWidgetConfig: WidgetConfig = {
  definition: { defaultRowSpan: 2, defaultColumnSpan: 2 },
  data: {
    icon: 'table',
    title: 'Up next',
    description: 'Up next',
    disableContentPaddings: false,
    header: UpNextWidgetHeader,
    content: UpNextWidgetContent,
  }
};

export default UpNextWidgetContent;
