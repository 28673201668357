/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const publishRunUpdates = /* GraphQL */ `mutation PublishRunUpdates(
  $id: ID
  $event: RunEventInput
  $createdAt: Int
  $updatedAt: Int
  $currentStatus: RunCurrentStatus
  $finishType: RunFinishType
  $player: RunPlayerInfoInput
  $stats: RunStatsInput
) {
  publishRunUpdates(
    id: $id
    event: $event
    createdAt: $createdAt
    updatedAt: $updatedAt
    currentStatus: $currentStatus
    finishType: $finishType
    player: $player
    stats: $stats
  ) {
    id
    internalId
    event {
      id
      sponsorName
      sponsorLogoUrl
      logoUrl
      title
      subtitle
      datetime
      location
      createdAt
      updatedAt
      currentStatus
      __typename
    }
    createdAt
    updatedAt
    startedAt
    endedAt
    difficulty
    currentStatus
    finishType
    player {
      firstName
      lastName
      email
      companyName
      companyRole
      __typename
    }
    stats {
      time
      reachedHolds
      totalHolds
      wrongHolds
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.PublishRunUpdatesMutationVariables,
  APITypes.PublishRunUpdatesMutation
>;
export const createRun = /* GraphQL */ `mutation CreateRun($event: RunEventInput!, $player: RunPlayerInfoInput!) {
  createRun(event: $event, player: $player) {
    id
    internalId
    event {
      id
      sponsorName
      sponsorLogoUrl
      logoUrl
      title
      subtitle
      datetime
      location
      createdAt
      updatedAt
      currentStatus
      __typename
    }
    createdAt
    updatedAt
    startedAt
    endedAt
    difficulty
    currentStatus
    finishType
    player {
      firstName
      lastName
      email
      companyName
      companyRole
      __typename
    }
    stats {
      time
      reachedHolds
      totalHolds
      wrongHolds
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRunMutationVariables,
  APITypes.CreateRunMutation
>;
export const cancelRun = /* GraphQL */ `mutation CancelRun($event: RunEventInput!, $id: ID!) {
  cancelRun(event: $event, id: $id) {
    id
    internalId
    event {
      id
      sponsorName
      sponsorLogoUrl
      logoUrl
      title
      subtitle
      datetime
      location
      createdAt
      updatedAt
      currentStatus
      __typename
    }
    createdAt
    updatedAt
    startedAt
    endedAt
    difficulty
    currentStatus
    finishType
    player {
      firstName
      lastName
      email
      companyName
      companyRole
      __typename
    }
    stats {
      time
      reachedHolds
      totalHolds
      wrongHolds
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CancelRunMutationVariables,
  APITypes.CancelRunMutation
>;
export const startCurrentRun = /* GraphQL */ `mutation StartCurrentRun(
  $internalId: ID!
  $event: RunEventInput!
  $difficulty: RunDifficulty!
  $updatedAt: Int
) {
  startCurrentRun(
    internalId: $internalId
    event: $event
    difficulty: $difficulty
    updatedAt: $updatedAt
  ) {
    id
    internalId
    event {
      id
      sponsorName
      sponsorLogoUrl
      logoUrl
      title
      subtitle
      datetime
      location
      createdAt
      updatedAt
      currentStatus
      __typename
    }
    createdAt
    updatedAt
    startedAt
    endedAt
    difficulty
    currentStatus
    finishType
    player {
      firstName
      lastName
      email
      companyName
      companyRole
      __typename
    }
    stats {
      time
      reachedHolds
      totalHolds
      wrongHolds
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.StartCurrentRunMutationVariables,
  APITypes.StartCurrentRunMutation
>;
export const updateCurrentRun = /* GraphQL */ `mutation UpdateCurrentRun(
  $internalId: ID!
  $event: RunEventInput!
  $stats: RunStatsInput!
  $updatedAt: Int
) {
  updateCurrentRun(
    internalId: $internalId
    event: $event
    stats: $stats
    updatedAt: $updatedAt
  ) {
    id
    internalId
    event {
      id
      sponsorName
      sponsorLogoUrl
      logoUrl
      title
      subtitle
      datetime
      location
      createdAt
      updatedAt
      currentStatus
      __typename
    }
    createdAt
    updatedAt
    startedAt
    endedAt
    difficulty
    currentStatus
    finishType
    player {
      firstName
      lastName
      email
      companyName
      companyRole
      __typename
    }
    stats {
      time
      reachedHolds
      totalHolds
      wrongHolds
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateCurrentRunMutationVariables,
  APITypes.UpdateCurrentRunMutation
>;
export const endCurrentRun = /* GraphQL */ `mutation EndCurrentRun(
  $internalId: ID!
  $event: RunEventInput!
  $finishType: RunFinishType!
  $stats: RunStatsInput!
  $updatedAt: Int
) {
  endCurrentRun(
    internalId: $internalId
    event: $event
    finishType: $finishType
    stats: $stats
    updatedAt: $updatedAt
  ) {
    id
    internalId
    event {
      id
      sponsorName
      sponsorLogoUrl
      logoUrl
      title
      subtitle
      datetime
      location
      createdAt
      updatedAt
      currentStatus
      __typename
    }
    createdAt
    updatedAt
    startedAt
    endedAt
    difficulty
    currentStatus
    finishType
    player {
      firstName
      lastName
      email
      companyName
      companyRole
      __typename
    }
    stats {
      time
      reachedHolds
      totalHolds
      wrongHolds
      __typename
    }
    __typename
  }
}
` as GeneratedMutation<
  APITypes.EndCurrentRunMutationVariables,
  APITypes.EndCurrentRunMutation
>;
