/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getEventCurrentRun = /* GraphQL */ `query GetEventCurrentRun($event: RunEventInput!) {
  getEventCurrentRun(event: $event) {
    id
    internalId
    event {
      id
      sponsorName
      sponsorLogoUrl
      logoUrl
      title
      subtitle
      datetime
      location
      createdAt
      updatedAt
      currentStatus
      __typename
    }
    createdAt
    updatedAt
    startedAt
    endedAt
    difficulty
    currentStatus
    finishType
    player {
      firstName
      lastName
      email
      companyName
      companyRole
      __typename
    }
    stats {
      time
      reachedHolds
      totalHolds
      wrongHolds
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEventCurrentRunQueryVariables,
  APITypes.GetEventCurrentRunQuery
>;
export const getEventLatestRun = /* GraphQL */ `query GetEventLatestRun($event: RunEventInput!) {
  getEventLatestRun(event: $event) {
    id
    internalId
    event {
      id
      sponsorName
      sponsorLogoUrl
      logoUrl
      title
      subtitle
      datetime
      location
      createdAt
      updatedAt
      currentStatus
      __typename
    }
    createdAt
    updatedAt
    startedAt
    endedAt
    difficulty
    currentStatus
    finishType
    player {
      firstName
      lastName
      email
      companyName
      companyRole
      __typename
    }
    stats {
      time
      reachedHolds
      totalHolds
      wrongHolds
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEventLatestRunQueryVariables,
  APITypes.GetEventLatestRunQuery
>;
export const getEventLeaderboard = /* GraphQL */ `query GetEventLeaderboard($event: RunEventInput!, $difficulty: RunDifficulty!) {
  getEventLeaderboard(event: $event, difficulty: $difficulty) {
    id
    internalId
    event {
      id
      sponsorName
      sponsorLogoUrl
      logoUrl
      title
      subtitle
      datetime
      location
      createdAt
      updatedAt
      currentStatus
      __typename
    }
    createdAt
    updatedAt
    startedAt
    endedAt
    difficulty
    currentStatus
    finishType
    player {
      firstName
      lastName
      email
      companyName
      companyRole
      __typename
    }
    stats {
      time
      reachedHolds
      totalHolds
      wrongHolds
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEventLeaderboardQueryVariables,
  APITypes.GetEventLeaderboardQuery
>;
export const getEventQueue = /* GraphQL */ `query GetEventQueue($event: RunEventInput!) {
  getEventQueue(event: $event) {
    id
    internalId
    event {
      id
      sponsorName
      sponsorLogoUrl
      logoUrl
      title
      subtitle
      datetime
      location
      createdAt
      updatedAt
      currentStatus
      __typename
    }
    createdAt
    updatedAt
    startedAt
    endedAt
    difficulty
    currentStatus
    finishType
    player {
      firstName
      lastName
      email
      companyName
      companyRole
      __typename
    }
    stats {
      time
      reachedHolds
      totalHolds
      wrongHolds
      __typename
    }
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetEventQueueQueryVariables,
  APITypes.GetEventQueueQuery
>;
