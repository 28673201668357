/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onRunUpdates = /* GraphQL */ `subscription OnRunUpdates {
  onRunUpdates {
    id
    internalId
    event {
      id
      sponsorName
      sponsorLogoUrl
      logoUrl
      title
      subtitle
      datetime
      location
      createdAt
      updatedAt
      currentStatus
      __typename
    }
    createdAt
    updatedAt
    startedAt
    endedAt
    difficulty
    currentStatus
    finishType
    player {
      firstName
      lastName
      email
      companyName
      companyRole
      __typename
    }
    stats {
      time
      reachedHolds
      totalHolds
      wrongHolds
      __typename
    }
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnRunUpdatesSubscriptionVariables,
  APITypes.OnRunUpdatesSubscription
>;
