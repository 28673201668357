import React, { useEffect, useRef, useState } from "react";
import Board from "@cloudscape-design/board-components/board";
import SpaceBetween from '@cloudscape-design/components/space-between';
import Button from '@cloudscape-design/components/button';
import { useContainerQuery } from '@cloudscape-design/component-toolkit';

import { load, remove, save, useLocalStorage } from '../../commons/useLocalStorage';
import { WidgetPlacement } from './interfaces/WidgetPlacement';
import { boardI18nStrings } from './boardItemI18nStrings';

import EmptyState from '../EmptyState';

import { BoardProps } from '@cloudscape-design/board-components/board';

import { LeaderboardEasyWidgetConfig } from '../LeaderboardEasyWidget';
import { LeaderboardMediumWidgetConfig } from '../LeaderboardMediumWidget';
import { LeaderboardHardWidgetConfig } from '../LeaderboardHardWidget';
import { LeaderboardExtremeWidgetConfig } from '../LeaderboardExtremeWidget';
import { UpNextWidgetConfig } from '../UpNextWidget';
import { CurrentRunStatsWidgetConfig } from '../CurrentRunStatsWidget';

import { zoneStatus } from '../SuccessFailWidget'

import ConfigurableWidget, { WidgetConfig, WidgetDataType } from '../ConfigurableWidget';

export const STORAGE_KEY = 'DASHBOARD-WIDGETS-LAYOUT';

export const ALL_WIDGETS: Record<string, WidgetConfig> = {
  LeaderboardEasyWidgetConfig,
  LeaderboardMediumWidgetConfig,
  LeaderboardHardWidgetConfig,
  LeaderboardExtremeWidgetConfig,
  UpNextWidgetConfig,
  zoneStatus,
  CurrentRunStatsWidgetConfig
};

/**
 * Main component representing the configurable dashboard.
 * @returns {JSX.Element} JSX element representing the configurable dashboard.
 */
const ConfigurableDashboard: () => JSX.Element = () => {

  function getDefaultLayout(
      width: number
  ) {
    return [
      { id: 'CurrentRunStatsWidgetConfig', columnSpan: 2, rowSpan: 3 },
      { id: 'UpNextWidgetConfig', columnSpan: 2, rowSpan: 3 },
      { id: 'LeaderboardExtremeWidgetConfig', columnSpan: 2, rowSpan: 3 },
      { id: 'LeaderboardHardWidgetConfig', columnSpan: 2, rowSpan: 3 },
      { id: 'LeaderboardMediumWidgetConfig', columnSpan: 2, rowSpan: 3 },
      { id: 'LeaderboardEasyWidgetConfig', columnSpan: 2, rowSpan: 3 }
    ];
  }

  function exportLayout(
    items: ReadonlyArray<BoardProps.Item<WidgetDataType>>
  ): ReadonlyArray<WidgetPlacement> {
    return items.map(item => ({
      id: item.id,
      columnSpan: item.columnSpan,
      columnOffset: item.columnOffset,
      rowSpan: item.rowSpan,
    }));
  }

  function getBoardWidgets(layout?: ReadonlyArray<WidgetPlacement>) {
    return (layout || []).map(position => {
      const widget = ALL_WIDGETS[position.id];
      return {
        ...position,
        ...widget,
        columnSpan: position.columnSpan ?? widget.definition?.defaultColumnSpan ?? 1,
        rowSpan: position.rowSpan ?? widget.definition?.defaultRowSpan ?? 2,
      };
    });
  }

  const [width, ref] = useContainerQuery(entry => entry.contentBoxWidth);
  const [layout, setLayout] = useState<ReadonlyArray<WidgetPlacement>>(() => load('ConfigurableDashboards-widgets-layout') ?? null);
  const itemsChanged = useRef(layout !== null);

  useEffect(() => {
    if (itemsChanged.current || !width) {
      return;
    }
    setLayout(getDefaultLayout(width));
  }, [width]);


  function resetLayout() {
    itemsChanged.current = false;
    remove(STORAGE_KEY);
    setLayout(getDefaultLayout(width!));
  }
  
  const [_, setSplitPanelOpen] = useState(false);

  return (
    <div ref={ref}>
      <Board
        empty={
          <EmptyState
            title="No widgets"
            description="There are no widgets on the dashboard."
            verticalCenter={true}
            action={
              <SpaceBetween direction="horizontal" size="xs">
                <Button onClick={resetLayout}>Reset to default layout</Button>
                <Button iconName="add-plus" onClick={() => setSplitPanelOpen(true)}>
                  Add widget
                </Button>
              </SpaceBetween>
            }
          />
        }
        i18nStrings={boardI18nStrings}
        items={getBoardWidgets(layout)}
        onItemsChange={({ detail: { items } }) => {
          setLayout(exportLayout(items));
        }}
        renderItem={(item, actions) => {
          const Wrapper = item.data.provider ?? React.Fragment;
          return (
            <Wrapper>
              <ConfigurableWidget config={item.data} onRemove={actions.removeItem} />
            </Wrapper>
          );
        }}
      />
    </div>
  );
}

export default ConfigurableDashboard;


