/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type RunEventInput = {
  id?: string | null,
};

export enum RunCurrentStatus {
  IN_QUEUE = "IN_QUEUE",
  RUNNING = "RUNNING",
  CANCELLED = "CANCELLED",
  FINISHED = "FINISHED",
}


export enum RunFinishType {
  SUCCESS = "SUCCESS",
  FAIL_FALL = "FAIL_FALL",
  FAIL_TIMEOUT = "FAIL_TIMEOUT",
}


export type RunPlayerInfoInput = {
  firstName: string,
  lastName: string,
  email: string,
  companyName: string,
  companyRole: string,
};

export type RunStatsInput = {
  time?: number | null,
  reachedHolds?: number | null,
  totalHolds?: number | null,
  wrongHolds?: Array< number | null > | null,
};

export type Run = {
  __typename: "Run",
  id?: string | null,
  internalId?: string | null,
  event?: Event | null,
  createdAt?: number | null,
  updatedAt?: number | null,
  startedAt?: number | null,
  endedAt?: number | null,
  difficulty?: RunDifficulty | null,
  currentStatus?: RunCurrentStatus | null,
  finishType?: RunFinishType | null,
  player?: RunPlayerInfo | null,
  stats?: RunStats | null,
};

export type Event = {
  __typename: "Event",
  id?: string | null,
  sponsorName?: string | null,
  sponsorLogoUrl?: string | null,
  logoUrl?: string | null,
  title?: string | null,
  subtitle?: string | null,
  datetime?: number | null,
  location?: string | null,
  createdAt?: number | null,
  updatedAt?: number | null,
  currentStatus?: EventCurrentStatus | null,
  leaderboard?:  Array<Run | null > | null,
  queue?:  Array<Run | null > | null,
};

export enum EventCurrentStatus {
  COMING_SOON = "COMING_SOON",
  RUNNING = "RUNNING",
  FINISHED = "FINISHED",
}


export enum RunDifficulty {
  EASY = "EASY",
  MEDIUM = "MEDIUM",
  HARD = "HARD",
  EXTREME = "EXTREME",
}


export type RunPlayerInfo = {
  __typename: "RunPlayerInfo",
  firstName?: string | null,
  lastName?: string | null,
  email?: string | null,
  companyName?: string | null,
  companyRole?: string | null,
};

export type RunStats = {
  __typename: "RunStats",
  time?: number | null,
  reachedHolds?: number | null,
  totalHolds?: number | null,
  wrongHolds?: Array< number | null > | null,
};

export type PublishRunUpdatesMutationVariables = {
  id?: string | null,
  event?: RunEventInput | null,
  createdAt?: number | null,
  updatedAt?: number | null,
  currentStatus?: RunCurrentStatus | null,
  finishType?: RunFinishType | null,
  player?: RunPlayerInfoInput | null,
  stats?: RunStatsInput | null,
};

export type PublishRunUpdatesMutation = {
  publishRunUpdates:  {
    __typename: "Run",
    id?: string | null,
    internalId?: string | null,
    event?:  {
      __typename: "Event",
      id?: string | null,
      sponsorName?: string | null,
      sponsorLogoUrl?: string | null,
      logoUrl?: string | null,
      title?: string | null,
      subtitle?: string | null,
      datetime?: number | null,
      location?: string | null,
      createdAt?: number | null,
      updatedAt?: number | null,
      currentStatus?: EventCurrentStatus | null,
    } | null,
    createdAt?: number | null,
    updatedAt?: number | null,
    startedAt?: number | null,
    endedAt?: number | null,
    difficulty?: RunDifficulty | null,
    currentStatus?: RunCurrentStatus | null,
    finishType?: RunFinishType | null,
    player?:  {
      __typename: "RunPlayerInfo",
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      companyName?: string | null,
      companyRole?: string | null,
    } | null,
    stats?:  {
      __typename: "RunStats",
      time?: number | null,
      reachedHolds?: number | null,
      totalHolds?: number | null,
      wrongHolds?: Array< number | null > | null,
    } | null,
  },
};

export type CreateRunMutationVariables = {
  event: RunEventInput,
  player: RunPlayerInfoInput,
};

export type CreateRunMutation = {
  createRun:  {
    __typename: "Run",
    id?: string | null,
    internalId?: string | null,
    event?:  {
      __typename: "Event",
      id?: string | null,
      sponsorName?: string | null,
      sponsorLogoUrl?: string | null,
      logoUrl?: string | null,
      title?: string | null,
      subtitle?: string | null,
      datetime?: number | null,
      location?: string | null,
      createdAt?: number | null,
      updatedAt?: number | null,
      currentStatus?: EventCurrentStatus | null,
    } | null,
    createdAt?: number | null,
    updatedAt?: number | null,
    startedAt?: number | null,
    endedAt?: number | null,
    difficulty?: RunDifficulty | null,
    currentStatus?: RunCurrentStatus | null,
    finishType?: RunFinishType | null,
    player?:  {
      __typename: "RunPlayerInfo",
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      companyName?: string | null,
      companyRole?: string | null,
    } | null,
    stats?:  {
      __typename: "RunStats",
      time?: number | null,
      reachedHolds?: number | null,
      totalHolds?: number | null,
      wrongHolds?: Array< number | null > | null,
    } | null,
  },
};

export type CancelRunMutationVariables = {
  event: RunEventInput,
  id: string,
};

export type CancelRunMutation = {
  cancelRun:  {
    __typename: "Run",
    id?: string | null,
    internalId?: string | null,
    event?:  {
      __typename: "Event",
      id?: string | null,
      sponsorName?: string | null,
      sponsorLogoUrl?: string | null,
      logoUrl?: string | null,
      title?: string | null,
      subtitle?: string | null,
      datetime?: number | null,
      location?: string | null,
      createdAt?: number | null,
      updatedAt?: number | null,
      currentStatus?: EventCurrentStatus | null,
    } | null,
    createdAt?: number | null,
    updatedAt?: number | null,
    startedAt?: number | null,
    endedAt?: number | null,
    difficulty?: RunDifficulty | null,
    currentStatus?: RunCurrentStatus | null,
    finishType?: RunFinishType | null,
    player?:  {
      __typename: "RunPlayerInfo",
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      companyName?: string | null,
      companyRole?: string | null,
    } | null,
    stats?:  {
      __typename: "RunStats",
      time?: number | null,
      reachedHolds?: number | null,
      totalHolds?: number | null,
      wrongHolds?: Array< number | null > | null,
    } | null,
  },
};

export type StartCurrentRunMutationVariables = {
  internalId: string,
  event: RunEventInput,
  difficulty: RunDifficulty,
  updatedAt?: number | null,
};

export type StartCurrentRunMutation = {
  startCurrentRun:  {
    __typename: "Run",
    id?: string | null,
    internalId?: string | null,
    event?:  {
      __typename: "Event",
      id?: string | null,
      sponsorName?: string | null,
      sponsorLogoUrl?: string | null,
      logoUrl?: string | null,
      title?: string | null,
      subtitle?: string | null,
      datetime?: number | null,
      location?: string | null,
      createdAt?: number | null,
      updatedAt?: number | null,
      currentStatus?: EventCurrentStatus | null,
    } | null,
    createdAt?: number | null,
    updatedAt?: number | null,
    startedAt?: number | null,
    endedAt?: number | null,
    difficulty?: RunDifficulty | null,
    currentStatus?: RunCurrentStatus | null,
    finishType?: RunFinishType | null,
    player?:  {
      __typename: "RunPlayerInfo",
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      companyName?: string | null,
      companyRole?: string | null,
    } | null,
    stats?:  {
      __typename: "RunStats",
      time?: number | null,
      reachedHolds?: number | null,
      totalHolds?: number | null,
      wrongHolds?: Array< number | null > | null,
    } | null,
  },
};

export type UpdateCurrentRunMutationVariables = {
  internalId: string,
  event: RunEventInput,
  stats: RunStatsInput,
  updatedAt?: number | null,
};

export type UpdateCurrentRunMutation = {
  updateCurrentRun:  {
    __typename: "Run",
    id?: string | null,
    internalId?: string | null,
    event?:  {
      __typename: "Event",
      id?: string | null,
      sponsorName?: string | null,
      sponsorLogoUrl?: string | null,
      logoUrl?: string | null,
      title?: string | null,
      subtitle?: string | null,
      datetime?: number | null,
      location?: string | null,
      createdAt?: number | null,
      updatedAt?: number | null,
      currentStatus?: EventCurrentStatus | null,
    } | null,
    createdAt?: number | null,
    updatedAt?: number | null,
    startedAt?: number | null,
    endedAt?: number | null,
    difficulty?: RunDifficulty | null,
    currentStatus?: RunCurrentStatus | null,
    finishType?: RunFinishType | null,
    player?:  {
      __typename: "RunPlayerInfo",
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      companyName?: string | null,
      companyRole?: string | null,
    } | null,
    stats?:  {
      __typename: "RunStats",
      time?: number | null,
      reachedHolds?: number | null,
      totalHolds?: number | null,
      wrongHolds?: Array< number | null > | null,
    } | null,
  },
};

export type EndCurrentRunMutationVariables = {
  internalId: string,
  event: RunEventInput,
  finishType: RunFinishType,
  stats: RunStatsInput,
  updatedAt?: number | null,
};

export type EndCurrentRunMutation = {
  endCurrentRun:  {
    __typename: "Run",
    id?: string | null,
    internalId?: string | null,
    event?:  {
      __typename: "Event",
      id?: string | null,
      sponsorName?: string | null,
      sponsorLogoUrl?: string | null,
      logoUrl?: string | null,
      title?: string | null,
      subtitle?: string | null,
      datetime?: number | null,
      location?: string | null,
      createdAt?: number | null,
      updatedAt?: number | null,
      currentStatus?: EventCurrentStatus | null,
    } | null,
    createdAt?: number | null,
    updatedAt?: number | null,
    startedAt?: number | null,
    endedAt?: number | null,
    difficulty?: RunDifficulty | null,
    currentStatus?: RunCurrentStatus | null,
    finishType?: RunFinishType | null,
    player?:  {
      __typename: "RunPlayerInfo",
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      companyName?: string | null,
      companyRole?: string | null,
    } | null,
    stats?:  {
      __typename: "RunStats",
      time?: number | null,
      reachedHolds?: number | null,
      totalHolds?: number | null,
      wrongHolds?: Array< number | null > | null,
    } | null,
  },
};

export type GetEventCurrentRunQueryVariables = {
  event: RunEventInput,
};

export type GetEventCurrentRunQuery = {
  getEventCurrentRun:  {
    __typename: "Run",
    id?: string | null,
    internalId?: string | null,
    event?:  {
      __typename: "Event",
      id?: string | null,
      sponsorName?: string | null,
      sponsorLogoUrl?: string | null,
      logoUrl?: string | null,
      title?: string | null,
      subtitle?: string | null,
      datetime?: number | null,
      location?: string | null,
      createdAt?: number | null,
      updatedAt?: number | null,
      currentStatus?: EventCurrentStatus | null,
    } | null,
    createdAt?: number | null,
    updatedAt?: number | null,
    startedAt?: number | null,
    endedAt?: number | null,
    difficulty?: RunDifficulty | null,
    currentStatus?: RunCurrentStatus | null,
    finishType?: RunFinishType | null,
    player?:  {
      __typename: "RunPlayerInfo",
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      companyName?: string | null,
      companyRole?: string | null,
    } | null,
    stats?:  {
      __typename: "RunStats",
      time?: number | null,
      reachedHolds?: number | null,
      totalHolds?: number | null,
      wrongHolds?: Array< number | null > | null,
    } | null,
  },
};

export type GetEventLatestRunQueryVariables = {
  event: RunEventInput,
};

export type GetEventLatestRunQuery = {
  getEventLatestRun:  {
    __typename: "Run",
    id?: string | null,
    internalId?: string | null,
    event?:  {
      __typename: "Event",
      id?: string | null,
      sponsorName?: string | null,
      sponsorLogoUrl?: string | null,
      logoUrl?: string | null,
      title?: string | null,
      subtitle?: string | null,
      datetime?: number | null,
      location?: string | null,
      createdAt?: number | null,
      updatedAt?: number | null,
      currentStatus?: EventCurrentStatus | null,
    } | null,
    createdAt?: number | null,
    updatedAt?: number | null,
    startedAt?: number | null,
    endedAt?: number | null,
    difficulty?: RunDifficulty | null,
    currentStatus?: RunCurrentStatus | null,
    finishType?: RunFinishType | null,
    player?:  {
      __typename: "RunPlayerInfo",
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      companyName?: string | null,
      companyRole?: string | null,
    } | null,
    stats?:  {
      __typename: "RunStats",
      time?: number | null,
      reachedHolds?: number | null,
      totalHolds?: number | null,
      wrongHolds?: Array< number | null > | null,
    } | null,
  },
};

export type GetEventLeaderboardQueryVariables = {
  event: RunEventInput,
  difficulty: RunDifficulty,
};

export type GetEventLeaderboardQuery = {
  getEventLeaderboard:  Array< {
    __typename: "Run",
    id?: string | null,
    internalId?: string | null,
    event?:  {
      __typename: "Event",
      id?: string | null,
      sponsorName?: string | null,
      sponsorLogoUrl?: string | null,
      logoUrl?: string | null,
      title?: string | null,
      subtitle?: string | null,
      datetime?: number | null,
      location?: string | null,
      createdAt?: number | null,
      updatedAt?: number | null,
      currentStatus?: EventCurrentStatus | null,
    } | null,
    createdAt?: number | null,
    updatedAt?: number | null,
    startedAt?: number | null,
    endedAt?: number | null,
    difficulty?: RunDifficulty | null,
    currentStatus?: RunCurrentStatus | null,
    finishType?: RunFinishType | null,
    player?:  {
      __typename: "RunPlayerInfo",
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      companyName?: string | null,
      companyRole?: string | null,
    } | null,
    stats?:  {
      __typename: "RunStats",
      time?: number | null,
      reachedHolds?: number | null,
      totalHolds?: number | null,
      wrongHolds?: Array< number | null > | null,
    } | null,
  } | null >,
};

export type GetEventQueueQueryVariables = {
  event: RunEventInput,
};

export type GetEventQueueQuery = {
  getEventQueue:  Array< {
    __typename: "Run",
    id?: string | null,
    internalId?: string | null,
    event?:  {
      __typename: "Event",
      id?: string | null,
      sponsorName?: string | null,
      sponsorLogoUrl?: string | null,
      logoUrl?: string | null,
      title?: string | null,
      subtitle?: string | null,
      datetime?: number | null,
      location?: string | null,
      createdAt?: number | null,
      updatedAt?: number | null,
      currentStatus?: EventCurrentStatus | null,
    } | null,
    createdAt?: number | null,
    updatedAt?: number | null,
    startedAt?: number | null,
    endedAt?: number | null,
    difficulty?: RunDifficulty | null,
    currentStatus?: RunCurrentStatus | null,
    finishType?: RunFinishType | null,
    player?:  {
      __typename: "RunPlayerInfo",
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      companyName?: string | null,
      companyRole?: string | null,
    } | null,
    stats?:  {
      __typename: "RunStats",
      time?: number | null,
      reachedHolds?: number | null,
      totalHolds?: number | null,
      wrongHolds?: Array< number | null > | null,
    } | null,
  } | null >,
};

export type OnRunUpdatesSubscriptionVariables = {
};

export type OnRunUpdatesSubscription = {
  onRunUpdates?:  {
    __typename: "Run",
    id?: string | null,
    internalId?: string | null,
    event?:  {
      __typename: "Event",
      id?: string | null,
      sponsorName?: string | null,
      sponsorLogoUrl?: string | null,
      logoUrl?: string | null,
      title?: string | null,
      subtitle?: string | null,
      datetime?: number | null,
      location?: string | null,
      createdAt?: number | null,
      updatedAt?: number | null,
      currentStatus?: EventCurrentStatus | null,
    } | null,
    createdAt?: number | null,
    updatedAt?: number | null,
    startedAt?: number | null,
    endedAt?: number | null,
    difficulty?: RunDifficulty | null,
    currentStatus?: RunCurrentStatus | null,
    finishType?: RunFinishType | null,
    player?:  {
      __typename: "RunPlayerInfo",
      firstName?: string | null,
      lastName?: string | null,
      email?: string | null,
      companyName?: string | null,
      companyRole?: string | null,
    } | null,
    stats?:  {
      __typename: "RunStats",
      time?: number | null,
      reachedHolds?: number | null,
      totalHolds?: number | null,
      wrongHolds?: Array< number | null > | null,
    } | null,
  } | null,
};
